import { commonLoadingStyles, styled } from '@/styles/theme'
import type { ComponentProps } from 'react'
import { BaseButton } from './BaseButton'

export const SecondaryButton = styled(BaseButton, {
  border: '1px solid $secondary50',

  variants: {
    variant: {
      default: {
        backgroundColor: '$white',
        color: '$secondary50',
        '&:not(:disabled):hover': {
          backgroundColor: '$secondary90',
          border: '1px solid $secondary90',
        },
        '&:active': {
          color: '$secondary10',
          backgroundColor: '$secondary70',
          border: '1px solid $secondary70',
        },
        '&:not(:disabled):hover:active': {
          backgroundColor: '$secondary70',
          outline: 'none',
        },
        '&:focus-visible': {
          outline: '4px solid $secondary95',
        },
        '&:disabled': {
          background: '$neutral90',
          cursor: 'auto',
          color: '$neutral70',
        },
      },
    },

    loading: {
      true: {
        '&:disabled': {
          color: '$white',
          background:
            'linear-gradient(to right, $secondary90 0% 50%, $white 50% 100%)',
          ...commonLoadingStyles,
        },
      },
    },
  },

  compoundVariants: [
    {
      variant: 'default',
      loading: true,
      css: {
        '&:disabled': {
          color: '$white',
          background:
            'linear-gradient(to right, $secondary90 0% 50%, $white 50% 100%)',
          ...commonLoadingStyles,
        },
      },
    },
  ],

  defaultVariants: {
    variant: 'default',
    loading: false,
  },
})

export type ButtonProps = ComponentProps<typeof SecondaryButton>

SecondaryButton.displayName = 'PrimaryButton'
