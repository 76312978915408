import { styled } from '@/styles/theme'
import { Title } from '../Typography'

export const FormDescription = styled('div', {
  width: '100%',
  height: 'fit-content',

  [`${Title}`]: {
    marginBottom: '22px',
  },
})

FormDescription.displayName = 'FormDescription'
