import { useAppState } from '@/contexts/AppStateContext'
import { IconCheck, IconX } from '@tabler/icons-react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import attentionIcon from 'public/assets/images/attention-icon.svg'
import { LinkButton } from '../../Button/LinkButton'
import { PillButtonSecondary } from '../../Button/PillButtonSecondary'
import { PrimaryButton } from '../../Button/PrimaryButton'
import { Text } from '../../Typography'
import {
  ModalClose,
  ModalContent,
  ModalDescription,
  ModalFooter,
  ModalOverlay,
  ModalPortal,
  ModalTitle,
} from '../styles'

export function CancelModal() {
  const router = useRouter()

  const { setCancelModalOpen } = useAppState()
  function handleModalConfirm() {
    setCancelModalOpen(false)
    return router.push('https://seja.flashapp.com.br/fale-com-a-ouvidoria')
  }

  return (
    <ModalPortal>
      <ModalOverlay />
      <ModalContent>
        <ModalClose asChild>
          <PillButtonSecondary variant="default">
            <IconX />
          </PillButtonSecondary>
        </ModalClose>
        <header>
          <Image
            src={attentionIcon}
            width={72}
            height={72}
            quality={100}
            alt=""
          />
          <Text size="body3">Atenção!</Text>
        </header>
        <div>
          <ModalTitle>Tem certeza que deseja cancelar?</ModalTitle>
          <ModalDescription>Seu progresso não será salvo.</ModalDescription>
        </div>

        <ModalFooter variant="cancel">
          <ModalClose asChild>
            <LinkButton variant="neutral" type="button">
              Cancelar
            </LinkButton>
          </ModalClose>
          <PrimaryButton
            type="button"
            variant="error"
            onClick={handleModalConfirm}
          >
            Confirmar
            <IconCheck />
          </PrimaryButton>
        </ModalFooter>
      </ModalContent>
    </ModalPortal>
  )
}
