import { styled } from '@/styles/theme'

export const BaseButton = styled('button', {
  all: 'unset',
  boxSizing: 'border-box',
  width: 'fit-content',
  padding: '$2 $3',
  borderRadius: '$md',
  border: 'transparent',
  cursor: 'pointer',
  fontFamily: '$secondary',
  fontWeight: '$bold',
  fontSize: '$base',
  transition: 'backgroundColor boxShadow 2s',

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$1',

  svg: {
    width: '$3',
    minHeight: '$3',
  },

  variants: {
    size: {
      sm: {
        minHeight: 40,
        padding: '$1 $2',
      },
      md: {
        minHeight: 48,
        padding: '12px $3',
      },
      lg: {
        minHeight: 60,
        padding: '$2 $3',
      },
    },
  },

  '@md': {
    width: 'fit-content',
  },

  defaultVariants: {
    size: 'md',
  },
})
