import { styled } from '@/styles/theme'
import { Text } from '../Typography'

export const StepperContainer = styled('div', {
  variants: {
    direction: {
      horizontal: {
        display: 'none',
      },
      vertical: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'space-between',
        padding: '44px 40px',
      },
    },
  },

  '@media(min-width: 768px)': {
    display: 'flex',
    gap: '$2',
    cursor: 'default',

    variants: {
      direction: {
        horizontal: {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        },
        vertical: {
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        },
      },
    },
  },

  defaultVariants: {
    direction: 'horizontal',
  },
})

export const StepIndicator = styled('div', {
  border: 'transparent',
  borderRadius: '$circular',
  backgroundColor: 'transparent',

  variants: {
    variant: {
      numbered: {
        width: '$3',
        height: '$3',

        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '$2',

        [`${Text}`]: {
          color: '$neutral50',
          fontWeight: '$semibold',
        },
      },
      dotted: {
        width: '$1',
        height: '$1',
        border: '1px solid $secondary95',
      },
    },
  },
  defaultVariants: {
    variant: 'numbered',
  },
})

export const StepLabel = styled(Text, {
  fontWeight: '$semibold',
})

export const Divider = styled('div', {
  width: '1px',
  height: '56px',
  border: '1px dashed $secondary95',
  marginLeft: '3.1px',
  marginTop: '-6px',
  marginBottom: '-6px',

  variants: {
    previous: {
      true: {
        border: '1px solid $secondary95',
      },
    },
    active: {
      true: {
        border: '1px solid $primary50',
      },
    },
  },

  '@md': {
    border: 0,
    margin: 0,
    width: '12px',
    height: '1px',
    backgroundColor: '$neutral90',
  },
})

export const Step = styled('div', {
  display: 'flex',
  gap: '$2',
  alignItems: 'center',
  height: '100%',

  '> div': {
    display: 'flex',
    alignItems: 'center',
    gap: '$1',
  },

  [`${StepIndicator}`]: {
    backgroundColor: 'transparent',

    [`${Text}`]: {
      color: '$neutral50',
    },
  },
  [`${StepLabel}`]: {
    color: '$neutral50',
  },

  variants: {
    direction: {
      horizontal: {
        alignItems: 'center',
      },
      vertical: {
        display: 'flex',
        gap: 0,
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    },

    previous: {
      true: {
        [`${StepIndicator}`]: {
          backgroundColor: '$secondary95',

          [`${Text}`]: {
            color: '$secondary50',
          },
        },
        [`${StepLabel}`]: {
          color: '$neutral60',
        },
      },
    },
    active: {
      true: {
        [`${StepIndicator}`]: {
          backgroundColor: '$secondary50',

          [`${Text}`]: {
            color: '$white',
          },
        },
        [`${StepLabel}`]: {
          color: '$secondary50',
        },
      },
    },
  },
})
