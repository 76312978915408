import { type ComponentProps, forwardRef } from 'react'

import { Field } from '@/components/Form/Field'
import { Input } from './styles'

export type TextFieldProps = ComponentProps<typeof Input> & {
  label: string
  error?: string
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  ({ label, error, className, ...props }, ref) => {
    return (
      <Field label={label} error={error}>
        <Input id={label} type="text" {...props} ref={ref} />
      </Field>
    )
  },
)

TextField.displayName = 'TextField'
