import { styled } from '@/styles/theme'

export const MenuButton = styled('button', {
  all: 'unset',
  boxSizing: 'border-box',
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid $neutral80',
  borderRadius: '$circular',
  width: '2.5rem',
  height: '2.5rem',
  padding: '12px',

  variants: {
    isOpen: {
      true: {},
      false: {},
    },

    variant: {
      default: {
        backgroundColor: 'transparent',
        color: '$neutral40',
        '&:not(:disabled):hover': {
          backgroundColor: '$neutral90',
          color: '$neutral30',
        },
        '&:active': {
          backgroundColor: '$neutral80',
          color: '$neutral30',
        },
        '&:not(:disabled):hover:active': {
          backgroundColor: '$neutral80',
          outline: 'none',
          boxShadow: 'none',
        },
        '&:focus': {
          outline: '4px solid $secondary95',
          border: 'transparent',
        },
        '&:disabled': {
          background: '$neutral90',
          cursor: 'auto',
          color: '$neutral70',
        },
      },
    },
  },

  defaultVariants: {
    variant: 'default',
  },

  '@media(min-width: 768px)': {
    display: 'none',
  },
})

export const Menu = styled('div', {
  backgroundColor: '$white',

  variants: {
    isOpen: {
      true: {
        width: '100%',
        height: 'calc(100vh - 100px)',
        '@md': {
          display: 'none',
        },
      },
      false: {
        display: 'none',
      },
    },
  },

  defaultVariants: {
    isOpen: false,
  },
})
