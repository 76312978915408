import { styled } from '@/styles/theme'

export const PillButtonSecondary = styled('button', {
  all: 'unset',
  boxSizing: 'border-box',
  cursor: 'pointer',
  borderRadius: '50%',
  width: 40,
  height: 40,

  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  svg: {
    width: 16,
    height: 16,
  },

  variants: {
    variant: {
      default: {
        background: '$white',
        color: '$neutral40',
        border: '1px solid $neutral80',

        '&:not(:disabled):hover': {
          background: '$neutral90',
          color: '$neutral30',
          border: '1px solid $neutral90',
        },

        '&:active': {
          background: '$neutral80',
          color: '$neutral30',
          border: '1px solid $neutral80',
        },

        '&:not(:disabled):hover:active': {
          background: '$neutral80',
          color: '$neutral30',
          border: '1px solid $neutral80',
          outline: 'none',
          boxShadow: 'none',
        },

        '&:focus-visible': {
          outline: '4px solid $secondary95',
          border: 'transparent',
        },

        '&:disabled': {
          background: '$neutral95',
          cursor: 'auto',
          color: '$neutral70',
          border: '1px solid $neutral95',
        },
      },
    },

    loading: {
      true: {
        '&:disabled': {
          color: '$white',
          border: '1px solid $neutral80',
        },
      },
    },

    size: {
      small: {
        width: 40,
        height: 40,
      },
    },
  },
})
