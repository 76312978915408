import { styled } from '@/styles/theme'

export const Title = styled('h2', {
  fontFamily: '$primary',
  fontWeight: '$bold',
  color: '$primary50',
  textWrap: 'balance',

  variants: {
    size: {
      headline6: {
        fontSize: '$xl',
        lineHeight: '$md',
      },
      headline7: {
        fontSize: '$lg',
        lineHeight: '$sm',
      },
      headline8: {
        fontSize: '$md',
        lineHeight: '$sm',
      },
    },
  },
})

export const Text = styled('p', {
  fontFamily: '$secondary',
  fontWeight: '$regular',
  color: '$neutral40',
  textWrap: 'pretty',

  variants: {
    size: {
      body3: { fontSize: '$base', lineHeight: '$lg' },
      body4: { fontSize: '$sm', lineHeight: '$lg' },
      caption1: { fontSize: '$xs', lineHeight: '$xl' },
    },
  },

  defaultVariants: {
    size: 'body3',
  },
})
