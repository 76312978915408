import { styled } from '@/styles/theme'
import { Text, Title } from '../Typography'

export const FormContainer = styled('div', {
  width: 'calc(100% - 24.5px)',
  display: 'flex',
  flexDirection: 'column',
  gap: '$3',

  '@md': {
    marginBottom: 'calc(82px + 24px)',
  },

  '@lg': {
    minHeight: 'calc(100vh - 300px)',
  },

  fieldset: {
    all: 'unset',
    boxSizing: 'border-box',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '$1',

    border: '1px solid $neutral90',
    borderRadius: '$sm',
    padding: '$5 $4',
    paddingBottom: '$2',

    [`& > ${Text}`]: {
      gap: 0,
      margin: '-$2 0',
      padding: '0 $2',
    },

    [`${Title}`]: {
      color: '$neutral30',
    },

    '> div': {
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
    },

    '@lg': {
      '>&:last-child': {
        marginBottom: '120px',
      },
    },
  },

  '> div': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',

    '@lg': {
      paddingBottom: 120,
    },
  },
})

FormContainer.displayName = 'FormContainer'
