import { styled } from '@/styles/theme'
import * as Progress from '@radix-ui/react-progress'

export const ProgressBarContainer = styled(Progress.Root, {
  height: 4,
  width: '100%',

  backgroundColor: '$transparent',

  position: 'absolute',
  top: 0,
  left: 0,

  '@md': {
    height: 3,

    top: 72,
    left: 0,
  },
})

export const ProgressIndicator = styled(Progress.Root, {
  height: '100%',
  borderRadius: '$sm',

  backgroundColor: '$primary50',
  transition: 'width 0.5s cubic-bezier(0.65, 0, 0.35, 1)',
})
