import { Text } from '@/components/Typography'
import { Label } from '@radix-ui/react-label'
import * as SelectPrimitive from '@radix-ui/react-select'
import { styled } from '@stitches/react'

export const SelectContainer = styled('div', {
  width: '100%',
})

export const SelectIcon = styled(SelectPrimitive.Icon, {
  paddingInlineEnd: 18,
  svg: {
    width: 24,
    height: 24,
    color: '$neutral50',
  },
})

export const SelectInnerContainer = styled('div', {
  width: '100%',
  height: '100%',
  backgroundColor: '$white',
  border: '1px solid $neutral70',
  borderRadius: '$md',
  padding: '9px $2',

  [`${Text}`]: {
    fontWeight: '$semibold',
    color: '$neutral50',

    div: {
      transition: 'all 0.15s ease-out',
    },
  },

  '&:focus-within': {
    outline: 'none',
    border: '2px solid $secondary70',
    [`${Text}`]: {
      color: '$neutral30',
    },
  },

  '&:invalid': {
    background: '$error90',
    border: '2px solid $error50',
    borderRadius: '$sm',
  },

  '&:disabled': {
    backgroundColor: '$neutral95',
    border: '1px solid $neutral80',
  },

  '&:has(input:read-only)': {
    backgroundColor: '$neutral95',
    color: '$neutral70',

    [`${Text}`]: {
      color: '$neutral70',
    },
  },

  variants: {
    hasError: {
      true: {
        background: '$error90',
        border: '2px solid $error50',
        borderRadius: '$sm',

        [`${SelectIcon}`]: {
          color: '$error50',
        },
      },
    },
  },
})

export const SelectLabel = styled(Label, {})

export const SelectRoot = styled(SelectPrimitive.Root, {})

export const SelectTrigger = styled(SelectPrimitive.Trigger, {
  all: 'unset',
  boxSizing: 'border-box',
  width: 'calc(100% + 24px)',
  border: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  outline: 'none',

  backgroundColor: 'transparent',
  '&[data-placeholder]': {
    fontFamily: '$secondary',
    color: '$neutral60',
    fontWeight: '$semibold',
    lineHeight: '$md',
  },
})

export const SelectValue = styled(SelectPrimitive.Value, {
  color: '$neutral40',
  fontFamily: '$secondary',
})

export const SelectContent = styled(SelectPrimitive.Content, {
  backgroundColor: '$white',
  borderRadius: '$md',

  overflow: 'hidden',
  boxShadow:
    '0px 10px 38px -10px rgba(22, 23, 24, 0.35), 0px 10px 20px -15px rgba(22, 23, 24, 0.2)',
})

// export const SelectScrollUpButton = styled(SelectPrimitive.ScrollUpButton, {
//   svg: {
//     width: 24,
//     height: 24,
//     color: '$secondary50',
//   },
// })

export const SelectViewPort = styled(SelectPrimitive.Viewport, {
  padding: '$2',
})

export const SelectGroup = styled(SelectPrimitive.Group, {})

export const SelectItemContainer = styled(SelectPrimitive.Item, {
  padding: '$2',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: '$white',
  outline: 'none',
  borderRadius: '$md',

  '&[data-highlighted]': {
    backgroundColor: '$secondary95',
  },
  '&[data-disabled]': {
    color: '$neutral60',
  },
  '&:focus-visible': {
    backgroundColor: '$secondary95',
  },

  [`${Text}`]: {
    color: '$neutral30',
  },

  svg: {
    width: 24,
    height: 24,
    color: '$secondary50',
  },
})

export const InputError = styled('div', {
  display: 'flex',
  alignItems: 'center',
  margin: '4px $2',
  gap: 6,

  svg: {
    width: '12px',
    height: '12px',
    color: '$error50',
  },
  [`${Text}`]: {
    color: '$neutral50',
    fontWeight: '$semibold',
  },
})

export const Spacer = styled('div', {
  height: 25.75,
})
