import { useAppState } from '@/contexts/AppStateContext'
import type { ComponentProps } from 'react'
import { Text } from '../Typography'
import {
  Divider,
  Step,
  StepIndicator,
  StepLabel,
  StepperContainer,
} from './styles'

type StepperProps = ComponentProps<typeof StepperContainer> & {
  steps: string[]
  variant: 'dotted' | 'numbered'
  direction: 'horizontal' | 'vertical'
  isMenuOpen?: boolean
}

export function Stepper({
  steps,
  direction,
  isMenuOpen,
  ...props
}: StepperProps) {
  const { currentStep } = useAppState()

  return (
    <StepperContainer direction={direction} {...props}>
      {steps.map((label, index) => {
        const isActive = currentStep === index + 1
        const isPrevious = currentStep > index + 1

        return (
          <Step
            key={label}
            active={isActive}
            previous={isPrevious}
            direction={direction}
          >
            <div>
              {isMenuOpen ? (
                <StepIndicator variant="dotted" />
              ) : (
                <StepIndicator variant="numbered">
                  <Text size="body4">{index + 1}</Text>
                </StepIndicator>
              )}
              <StepLabel size="body4">{label}</StepLabel>
            </div>
            {index < steps.length - 1 && (
              <Divider active={isActive} previous={isPrevious} />
            )}
          </Step>
        )
      })}
    </StepperContainer>
  )
}
