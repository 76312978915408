import type { ComponentProps } from 'react'
import { ProgressBarContainer, ProgressIndicator } from './styles'

type ProgressBarProps = ComponentProps<typeof ProgressBarContainer> & {
  value: number
  max: number
}

export function ProgressBar({ value, max }: ProgressBarProps) {
  const progressIndicatorWidth = (value * 100) / max

  return (
    <ProgressBarContainer
      title={`${value} de ${max} concluídos`}
      aria-label={`${value} de ${max} concluídos`}
    >
      <ProgressIndicator
        style={{
          width: `${progressIndicatorWidth}%`,
        }}
        title={`${value} de ${max} concluídos`}
        aria-label={`${value} de ${max} concluídos`}
      />
    </ProgressBarContainer>
  )
}
