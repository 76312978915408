import { commonLoadingStyles, styled } from '@/styles/theme'
import type { ComponentProps } from 'react'
import { BaseButton } from './BaseButton'

export const PrimaryButton = styled(BaseButton, {
  variants: {
    variant: {
      default: {
        backgroundColor: '$secondary50',
        color: '$white',
        '&:not(:disabled):hover': {
          backgroundColor: '$primary50',
          boxShadow: '$primaryGlow4',
        },
        '&:active': {
          backgroundColor: '$secondary40',
        },
        '&:not(:disabled):hover:active': {
          backgroundColor: '$secondary40',
          outline: 'none',
          boxShadow: 'none',
        },
        '&:focus-visible': {
          outline: '4px solid $secondary95',
        },
        '&:disabled': {
          background: '$neutral90',
          cursor: 'auto',
          color: '$neutral70',
        },
      },

      error: {
        backgroundColor: '$error70',
        color: '$error10',
        '&:not(:disabled):hover': {
          boxShadow: '$errorGlow4',
        },
        '&:active': {
          backgroundColor: '$error50',
        },
        '&:not(:disabled):hover:active': {
          backgroundColor: '$error50',
          outline: 'none',
          boxShadow: 'none',
        },
        '&:focus-visible': {
          outline: '4px solid $error90',
        },
        '&:disabled': {
          background: '$neutral90',
          cursor: 'auto',
          color: '$neutral70',
        },
      },
    },

    loading: {
      true: {
        '&:disabled': {
          color: '$white',
          background:
            'linear-gradient(to right, $secondary30 0% 50%, $secondary40 50% 100%)',
          ...commonLoadingStyles,
        },
      },
    },
  },

  compoundVariants: [
    {
      variant: 'default',
      loading: true,
      css: {
        '&:disabled': {
          color: '$white',
          background:
            'linear-gradient(to right, $secondary30 0% 50%, $secondary40 50% 100%)',
          ...commonLoadingStyles,
        },
      },
    },
    {
      variant: 'error',
      loading: true,
      css: {
        color: '$error10',
        background:
          'linear-gradient(to right, $error40 0% 50%, $error50 50% 100%)',
        ...commonLoadingStyles,

        '&:disabled': {
          color: '$error10',
          background:
            'linear-gradient(to right, $error40 0% 50%, $error50 50% 100%)',
          ...commonLoadingStyles,
        },
      },
    },
  ],

  defaultVariants: {
    variant: 'default',
    loading: false,
  },
})

export type ButtonProps = ComponentProps<typeof PrimaryButton>

PrimaryButton.displayName = 'PrimaryButton'
