import { styled } from '@/styles/theme'

import Image from 'next/image'

export const HeaderContainer = styled('header', {
  width: '100%',
  minHeight: 68,
  height: 68,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 $3',
  gap: '$2',
  borderBottom: '1px solid $neutral90',

  backgroundColor: '$neutral95',

  '@media(min-width: 768px)': {
    minHeight: 75,
    maxHeight: 75,
    height: 75,
    backgroundColor: '$white',
    padding: '0 $3',

    div: {
      display: 'flex',
      gap: '1rem',
      cursor: 'default',
    },
  },
})

export const FlashLogo = styled(Image, {})
