import Image from 'next/image'
import { type ReactNode, useState } from 'react'
import { useMediaQuery } from 'usehooks-ts'

import { Header } from '@/components/Header'
import { Menu } from '@/components/Header/MenuButton'
import { Stepper } from '@/components/Stepper'
import { Text } from '@/components/Typography'
import { Container, ContentBox } from './styles'

import flashLogoImg from '../../../public/assets/images/flash-logo-footer.svg'

export type LayoutProps = {
  children: ReactNode
}

const steps = ['Identificação', 'Mensagem']

export function AppLayout({ children }: LayoutProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  const matchesSm = useMediaQuery('(max-width: 767px)')

  function handleMenuToggle() {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <>
      <Header
        steps={steps}
        isMenuOpen={isMenuOpen}
        onMenuToggle={handleMenuToggle}
      />
      <Container isMenuOpen={isMenuOpen}>
        <ContentBox>{children}</ContentBox>
        {matchesSm ? (
          <footer>
            <Text size="caption1">Realizado por</Text>
            <Image src={flashLogoImg} alt="Flash" width={44} quality={100} />
          </footer>
        ) : null}
      </Container>
      {!!steps.length && (
        <Menu isOpen={isMenuOpen}>
          <Stepper
            variant="dotted"
            direction="vertical"
            steps={steps}
            isMenuOpen={isMenuOpen}
          />
        </Menu>
      )}
    </>
  )
}
