import { IconInfoCircle } from '@tabler/icons-react'
import type { ComponentProps } from 'react'

import { Text } from '@/components/Typography'
import {
  FieldContainer,
  FieldContent,
  InputError,
  Spacer,
  TextAreaHint,
} from './styles'
interface FieldProps extends ComponentProps<typeof FieldContainer> {
  children: React.JSX.Element
  label: string
  error?: string
  maxLength?: number
  value?: string | number | readonly string[]
}

export function Field({
  children,
  label,
  error,
  maxLength,
  value,
  ...props
}: FieldProps) {
  const hasHint = maxLength && maxLength > 0

  return (
    <FieldContainer {...props}>
      <FieldContent hasError={!!error}>
        <Text as="label" htmlFor={label} size="caption1">
          {label}
        </Text>
        {children}
      </FieldContent>
      {hasHint && (
        <TextAreaHint id="textarea-hint">
          <Text size="caption1">Máximo de {maxLength} caracteres</Text>
          <Text size="caption1">{`${value ? String(value).length : 0}/${maxLength}`}</Text>
        </TextAreaHint>
      )}
      {error ? (
        <InputError>
          <IconInfoCircle />
          <Text size="caption1">{error}</Text>
        </InputError>
      ) : (
        <Spacer />
      )}
    </FieldContainer>
  )
}
