import { styled } from '@/styles/theme'

export const Input = styled('input', {
  all: 'unset',
  boxSizing: 'border-box',
  width: '100%',
  border: 'transparent',
  outline: 'none',
  backgroundColor: 'transparent',
  color: '$neutral30',
  fontFamily: '$secondary',
  fontSize: '$base',
  fontWeight: '$semibold',
  lineHeight: '$md',

  '&::placeholder': {
    color: '$neutral60',
    fontWeight: '$semibold',
    lineHeight: '$md',
  },

  '&:read-only': {
    color: '$neutral70',
  },

  // Testing styles for auto filled inputs
  // '&:-webkit-autofill': {
  //   '-webkit-background-clip': 'text',
  //   '-webkit-text-fill-color': '$neutral30',
  //   transition: 'backgroundColor 5000s ease-in-out 0s',
  //   boxShadow: 'inset 0 0 20px 20px transparent',
  // },
  // '&:-webkit-autofill:hover': {
  //   '-webkit-background-clip': 'text',
  //   '-webkit-text-fill-color': '$neutral30',
  //   transition: 'backgroundColor 5000s ease-in-out 0s',
  //   boxShadow: 'inset 0 0 20px 20px transparent',
  // },
  // '&:-webkit-autofill:focus': {
  //   '-webkit-background-clip': 'text',
  //   '-webkit-text-fill-color': '$neutral30',
  //   transition: 'background-color 5000s ease-in-out 0s',
  //   boxShadow: 'inset 0 0 20px 20px transparent',
  // },
  // '&:-webkit-autofill:active': {
  //   '-webkit-background-clip': 'text',
  //   '-webkit-text-fill-color': '$neutral30',
  //   transition: 'background-color 5000s ease-in-out 0s',
  //   boxShadow: 'inset 0 0 20px 20px transparent',
  // },
})
