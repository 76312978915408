import { IconMenu2, IconX } from '@tabler/icons-react'
import Image from 'next/image'
import type { ComponentProps } from 'react'

import { useAppState } from '@/contexts/AppStateContext'
import { ProgressBar } from '../ProgressBar'
import { Stepper } from '../Stepper'
import { MenuButton } from './MenuButton'
import { HeaderContainer } from './styles'

import flashLogo from 'public/assets/images/flash-logo-header.svg'

type HeaderProps = ComponentProps<typeof HeaderContainer> & {
  steps?: string[]
  isMenuOpen?: boolean
  onMenuToggle?: () => void
}

export function Header({
  steps,
  isMenuOpen,
  onMenuToggle,
  ...props
}: HeaderProps) {
  const { currentStep } = useAppState()

  return (
    <HeaderContainer {...props}>
      <Image src={flashLogo} width={112.69} height={40} quality={100} alt="" />

      <Stepper
        variant="numbered"
        direction="horizontal"
        steps={steps}
        isMenuOpen={false}
      />

      <MenuButton
        aria-label={isMenuOpen ? 'fechar menu' : 'abrir menu'}
        title={isMenuOpen ? 'Fechar menu' : 'Abrir menu'}
        isOpen={isMenuOpen}
        onClick={onMenuToggle}
      >
        {isMenuOpen ? <IconX /> : <IconMenu2 />}
      </MenuButton>

      <ProgressBar
        value={currentStep}
        max={steps.length}
        aria-valuemin={1}
        aria-valuemax={steps.length}
        aria-valuenow={currentStep}
      />
    </HeaderContainer>
  )
}
