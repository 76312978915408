import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react'
import type { FormEvent } from 'react'
import { useMediaQuery } from 'usehooks-ts'

import { LinkButton } from '../Button/LinkButton'
import { PrimaryButton } from '../Button/PrimaryButton'
import { SecondaryButton } from '../Button/SecondaryButton'
import { CancelModal } from '../Modals/CancelModal'
import { ModalRoot, ModalTrigger } from '../Modals/styles'

type FooterActionsProps = {
  cancelModalOpen?: boolean
  onCancelModalOpen?: (open: boolean) => void
  onHandleGoBack?: (event: FormEvent) => void
  canGoBack?: boolean
  primaryButtonDisabled: boolean
  primaryButtonText?: string
  loading: boolean
  trackEvents?: {
    cancel: string
  }
}

export function FooterActions({
  cancelModalOpen,
  onCancelModalOpen,
  canGoBack = false,
  onHandleGoBack,
  primaryButtonDisabled,
  primaryButtonText = 'Continuar',
  loading,
}: FooterActionsProps) {
  const matchesMd = useMediaQuery('(min-width: 768px)')
  const matchesSm = useMediaQuery('(max-width: 767px)')

  const shouldRenderCancelButton = matchesMd || !canGoBack
  const shouldRenderGoBackButton = () => {
    if (canGoBack) {
      if (matchesSm) {
        return (
          <LinkButton type="button" onClick={onHandleGoBack} variant="default">
            Voltar
          </LinkButton>
        )
      }
      return (
        <SecondaryButton
          type="button"
          variant="default"
          size="md"
          onClick={onHandleGoBack}
        >
          <IconArrowLeft />
          Voltar
        </SecondaryButton>
      )
    }

    return null
  }

  return (
    <div className="bottomActions">
      <ModalRoot modal open={cancelModalOpen} onOpenChange={onCancelModalOpen}>
        {shouldRenderCancelButton && (
          <ModalTrigger asChild>
            <LinkButton type="button" variant="neutral">
              Cancelar
            </LinkButton>
          </ModalTrigger>
        )}

        <CancelModal />
      </ModalRoot>
      <div>
        {shouldRenderGoBackButton()}
        <PrimaryButton
          type="submit"
          size="md"
          disabled={primaryButtonDisabled}
          loading={loading}
          style={{ width: `${matchesSm ? '100%' : 'fit-content'}` }}
        >
          {primaryButtonText}
          <IconArrowRight />
        </PrimaryButton>
      </div>
    </div>
  )
}
