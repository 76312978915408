import { BaseBox } from '@/components/Box/BaseBox'
import { LinkButton } from '@/components/Button/LinkButton'
import { styled } from '@/styles/theme'

export const Container = styled('div', {
  position: 'relative',
  width: '100%',
  padding: 0,

  display: 'flex',
  flexDirection: 'column',
  minHeight: 'calc(100dvh - 160px)',
  justifyContent: 'space-between',

  '@md': {
    minHeight: 'calc(100dvh - 80px)',
  },

  variants: {
    isMenuOpen: {
      true: {
        display: 'none',
      },
      false: {
        minWidth: 360,
        width: '100%',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  },

  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '$neutral95',
    minHeight: '82px',
    maxHeight: '160px',
    gap: '$1',
  },
})

export const ContentBox = styled(BaseBox, {
  width: '100%',
  padding: '$5 $3 $3 $3',
  borderRadius: 0,

  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',

  'form > div': {
    position: 'relative',
    margin: '0 auto',
    width: '100%',
    flex: 1,

    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1fr',
    gap: '28px',
    justifyContent: 'space-between',
  },

  '.bottomActions': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '$1',
    minHeight: '160px',
    width: '100%',
    padding: '12px $2',
    paddingLeft: 0,

    '> div': {
      width: '100%',
      display: 'flex',
      flexDirection: 'column-reverse',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '$3',
    },

    '@md': {
      minHeight: '80px',
      position: 'fixed',
      background: '$white',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderTop: '1px solid $neutral90',
      padding: '12px $3 12px $2',
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',

      '> div': {
        gap: '$2',
        flexDirection: 'row',
        justifyContent: 'flex-end',
      },
    },

    [`&:has(${LinkButton})`]: {
      flexDirection: 'column-reverse',

      '@md': {
        flexDirection: 'row',
      },
    },
  },

  '@md': {
    padding: '$10 $8 0px $8',
    borderRadius: '$sm',
    gridTemplateRows: 'auto 1fr auto',
  },

  '@lg': {
    padding: '$10 $10 0px $10',

    'form > div': {
      display: 'grid',
      gridTemplateColumns: '32% 60%',
      gridTemplateRows: '1fr',
      gap: 'clamp(8%, 8%, 98px)',
    },
  },

  '@xl': {
    padding: '$10 $14 0px $14',
  },
})
